export const ExchangeFees = {
    ["independentreserve"]: [
        [0, 49999.99, 0.50],
        [50000, 99999.99, 0.48],
        [100000, 199999.99, 0.46],
        [200000, 299999.99, 0.44],
        [300000, 399999.99, 0.42],
        [400000, 499999.99, 0.40],
    ],
    ["btcmarkets"]: [
        [0, 500, 0.85],
        [500.01, 1000, 0.83],
        [1000.01, 3000, 0.80],
        [3000.01, 9000, 0.75],
        [9000.01, 18000, 0.70],
        [18000.01, 40000, 0.65],
        [40000.01, 60000, 0.60],
        [60000.01, 70000, 0.55],
        [70000.01, 80000, 0.50],
        [80000.01, 90000, 0.45],
        [90000.01, 115000, 0.40],
        [115000.01, 125000, 0.35],
        [125000.01, 200000, 0.30],
        [200000.01, 400000, 0.25]
    ],
    ["krakenSpot"]: [
        [0, 50000, 0.26],
        [50001, 100000, 0.24],
        [100001, 250000, 0.22],
        [250001, 500000, 0.20]
    ],
    ["krakenFX"]: [
        [0, 50000, 0.20],
        [50001, 100000, 0.16],
        [100001, 250000, 0.12],
        [250001, 500000, 0.08]
    ],
    ["coinjar"]: [
        [0, 1000000, 0.10],
    ],
    ["whitebit"]: [
        [0, 9999999, 0.10]
    ],
    ["okx"]: [
        [0, 4999999, 0.10]
    ],
    ["binance"]: [
        [0, 1000000, 0.10]
    ],
    ["bybit"]: [
        [0, 1000000, 0.10]
    ],
    ["kucoin"]: [
        [0, 1000000, 0.10]
    ],
    ["coinbase"]: [
        [0, 10000, 0.60],
        [10001, 50000, 0.40],
        [50001, 100000, 0.25],
        [100001, 1000000, 0.20]
    ],
    ["cryptocom"]: [
        [0, 249999, 0.075],
        [250000, 999999, 0.072]
    ],
    ["gateio"]: [
        [0, 59999, 0.1],
        [60000, 119999, 0.099],
        [120000, 239999, 0.098],
        [240000, 499999, 0.097]
    ],
    ["huobi"]: [
        [0, 1000000, 0.20]
    ],
    ["bitget"]: [
        [0, 1000000, 0.10]
    ],
    ["mexc"]: [
        [0, 1000000, 0]
    ],
    ["poloniex"]: [
        [0, 9999, 0.2],
        [10000, 79999, 0.12],
        [80000, 499999, 0.12],
        [500000, 999999, 0.12]
    ],
    ["bitmart"]: [
        [0, 1000000, 0.10]
    ],
    ["lbank"]: [
        [0, 1000000, 0.10]
    ],
    ["digifinex"]: [
        [0, 99999, 0.20],
        [100000, 999999, 0.18]
    ],
    ["bitforex"]: [
        [0, 1000000, 0.10]
    ],
    ["coinw"]: [
        [0, 1000000, 0.20]
    ],
    ["gemini"]: [
        [0, 9999, 0.40],
        [10000, 49999, 0.30],
        [50000, 99999, 0.25],
        [100000, 999999, 0.20]
    ],
    ["bitstampSpot"]: [
        [0, 1000, 0],
        [1001, 10000, 0.40],
        [10001, 100000, 0.30],
        [100001, 500000, 0.20],
        [500001, 1500000, 0.18]
    ],
    ["bitstampFX"]: [
        [0, 1000, 0],
        [1001, 10000, 0.08],
        [10001, 100000, 0.06],
        [100001, 500000, 0.04],
        [500001, 1500000, 0.036]
    ],
    ["blockchaincom"]: [
        [0, 9999, 0.45],
        [10000, 49999, 0.35],
        [50000, 99999, 0.18],
        [100000, 499999, 0.18],
        [500000, 999999, 0.18]
    ],
    ["coinspot"]: [
        [0, 10000000, 0.1]
    ]
}
