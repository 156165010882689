import {createRoot} from 'react-dom/client';
import React, { Component, useEffect, useRef, useState } from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './style.css'

// import Binance from './components/Binance';
// import BTCMarkets from './components/BTCMarkets';
// import IndependentReserve from './components/IndependentReserve';

import { ExchangeDataProvider } from './contexts/ExchangeDataContext';
import { CustomFeesProvider } from './contexts/CustomFeesContext';

import PriceComparison from './components/PriceComparison';
import CustomFees from './components/CustomFees';
import Footer from './components/Footer';
import Header from './components/Header';
import NavBar from './components/UI/NavBar';

import Exchanges from './pages/exchanges';
import Exchange from './pages/exchange';

export default function App (){

    return (
        <Router>
            <NavBar>
                {/* <Header /> */}
                <Routes>
                    <Route path="/" element={<ExchangeDataProvider>
                        <CustomFeesProvider>
                            <PriceComparison />
                        </CustomFeesProvider>
                    </ExchangeDataProvider>} />
                    <Route path="/exchanges" element={<Exchanges />} />
                    <Route path="/exchanges/:exchange" element={<Exchange />} />
                </Routes>
                <Footer />
            </NavBar>
        </Router>
    )
}

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(<App />);
//ReactDOM.render(<App />, document.getElementById('root'))
