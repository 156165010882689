import React, {createContext, useReducer} from "react"

import {ExchangeDataReducer, ExchangeDataActions} from "../reducers/ExchangeDataReducer";
//import Comparison from "../components/Comparison";

const initialState = {"cryptoToken": {}, "count": 0}

export const ExchangeDataContext = createContext(initialState);

export function ExchangeDataProvider({children}){
    const [state, dispatch] = useReducer(ExchangeDataReducer,initialState)
    const value = {
        orderbook: state,
        setOrderbook: (data) => {
            //console.log(data)
            dispatch({type: ExchangeDataActions.SET_EXCHANGE_DATA, data})
        },
    }

    return (
        <ExchangeDataContext.Provider value={value}>
            {children}
        </ExchangeDataContext.Provider>
    )
}