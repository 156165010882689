import React, {Component, useEffect, useState, useContext, useRef, useMemo} from 'react'
import { useParams } from "react-router-dom";
import DataTable, { createTheme } from "react-data-table-component";
import axios from 'axios';
import styled from  'styled-components';

import { ExchangeNames } from "../constants/ExchangeNames";
import { ExchangeProfile } from "../constants/ExchangeProfile";
import { Countries } from "../constants/Countries";

import { BackendLinks } from '../constants/BackendLinks';

createTheme('solarized', {
    text: {
      primary: '#C8CBD0',
      secondary: '#2aa198',
    },
    background: {
      default: '#10172A',
    },
    context: {
      background: '#cb4b16',
      text: '#FFFFFF',
    },
    divider: {
      default: '#172442',
    },
    action: {
      button: 'rgba(0,0,0,.54)',
      hover: 'rgba(0,0,0,.08)',
      disabled: 'rgba(0,0,0,.12)',
    },
}, 'dark');

const TextField = styled.input`
  height: 32px;
  width: 200px;
  border-radius: 3px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border: 1px solid #172442;
  padding: 0 32px 0 16px;

`;

const ClearButton = styled.button`
  border-radius: 3px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  border: 1px solid #172442;
  height: 32px;
  width: 32px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    cursor: pointer;
    background-color: rgba(0,0,0,.08);
  }
`;

const FilterComponent = ({ filterText, onFilter, onClear }) => (
    <>
        <p className="pr-2 text-xs italic">Data gathered from exchange & calculated every 5 mins</p>
        <TextField
            id="search"
            type="text"
            placeholder="Filter By Name"
            aria-label="Search Input"
            value={filterText}
            onChange={onFilter}
        />
        <ClearButton type="button" onClick={onClear}>
            X
        </ClearButton>
    </>
);

export default function Exchange() {
    let params = useParams();

    // const companyCols = [
    //     {
    //         name: 'Country',
    //         selector: row => row.country,
    //         wrap: true,
    //         cell: row => <p className="tooltip tooltip-right text-2xl" data-tip={Countries[row.country].name}>{Countries[row.country].flag}</p>,
    //         grow: 0,
    //     },
    //     {
    //         name: 'Company Name',
    //         selector: row => row.name,
    //         wrap: true,
    //     },
    //     {
    //         name: 'Business Number/Identitfier',
    //         selector: row => row.busNum,
    //         wrap: true,
    //     },
    //     {
    //         name: 'Source',
    //         selector: row => row.source,
    //         wrap: true,
    //     },
    // ]
    // const companyData = ExchangeProfile[params.exchange].companies;

    // const licenseCols = [
    //     {
    //         name: "Status",
    //         selector: row => row.active,
    //         wrap: true,
    //         grow: 0,
    //         cell: row => <div><p>{row.active && `Reason: ${row.active[1]}`}</p><p>{row.active && row.active[2] && `Date: ${row.active[2]}`}</p></div>,
    //         conditionalCellStyles: [
    //             {
    //                 when: row => !row.active,
    //                 style: {
    //                     backgroundColor: 'oklch(var(--su))',
    //                 },
    //             },
    //             {
    //                 when: row => row.active && row.active[0] == false,
    //                 style: {
    //                     backgroundColor: 'oklch(var(--er))',
    //                     color: 'oklch(var(--erc))'
    //                 },
    //             },
    //             {
    //                 when: row => row.active && row.active[1] == "Pending",
    //                 style: {
    //                     backgroundColor: 'oklch(var(--wa))',
    //                     color: 'oklch(var(--wac))'
    //                 },
    //             },
    //         ],
    //     },
    //     {
    //         name: 'Country',
    //         selector: row => row.country,
    //         wrap: true,
    //         cell: row => <p className="tooltip tooltip-right text-2xl" data-tip={Countries[row.country].name}>{Countries[row.country].flag}</p>,
    //         grow: 0,
    //     },
    //     {
    //         name: 'Company Name',
    //         selector: row => row.name,
    //         wrap: true,
    //     },
    //     {
    //         name: 'License Number/Identifier',
    //         selector: row => row.licNum,
    //         wrap: true,
    //     },
    //     {
    //         name: 'Source',
    //         selector: row => row.source,
    //         wrap: true,
    //     },
    // ]
    // const licenseStatesCols = [
    //     {
    //         name: 'Status',
    //         selector: row => row.active,
    //         wrap: true,
    //         grow: 0,
    //         cell: row => <div><p>{row.active ? `Reason: ${row.active[1]}` : `Active`}</p><p>{row.active && `Date: ${row.active[2]}`}</p></div>,
    //         conditionalCellStyles: [
    //             {
    //                 when: row => !row.active,
    //                 style: {
    //                     backgroundColor: 'oklch(var(--su))',
    //                     color: 'oklch(var(--suc))'
    //                 },
    //             },
    //             {
    //                 when: row => row.active && row.active[0] == false,
    //                 style: {
    //                     backgroundColor: 'oklch(var(--er))',
    //                     color: 'oklch(var(--erc))'
    //                 },
    //             },
    //         ],
    //     },
    //     {
    //         name: 'State',
    //         selector: row => row.state,
    //         wrap: true,
    //     },
    //     {
    //         name: 'License Name',
    //         selector: row => row.license,
    //         wrap: true,
    //     },
    //     {
    //         name: 'License Number/Identifier',
    //         selector: row => row.licNum,
    //         wrap: true,
    //     },
    //     {
    //         name: 'Date Issued (DD-MM-YYYY)',
    //         selector: row => row.issueDate,
    //         wrap: true,
    //     },
    // ]
    // const licenseData = ExchangeProfile[params.exchange].licenses;
    // const licenseStatesComponent = ({data}) => <DataTable columns={licenseStatesCols} data={data.states} theme="solarized"/>

    const [assetUptime, setAssetUptime] = useState([])
    const [filterText, setFilterText] = useState('');
    const [filteredItems, setFilteredItems] = useState([]);

    // useEffect(() => {
    //     const filtered = Object.entries(assetUptime)
    //     .filter(([key, value]) => value.asset && value.asset.toLowerCase().includes(filterText.toLowerCase()))
    //     .map(([key, value]) => ( value ))
    //     console.log(filtered)
    //     setFilteredItems(filtered);
    // }, [filterText]);

    // useEffect(() => {
    //     axios.get(`${BackendLinks["api"]}/asset-uptime?exchange=${params.exchange}`).then((response) => {
    //         if (response.data == null){
    //             return
    //         }

    //         const rAssetUptime = response.data
    //         console.log(rAssetUptime)
    //         const formattedAssetUptime = []
            
    //         Object.keys(rAssetUptime).map((asset) => {
    //             const [assetID, network] = asset.split("~")
    //             const networkRow = {network: "", withdrawStatus: null, withdrawUptime: null, depositStatus: null, depositUptime: null, beginTimestamp: null, latestTimestamp: null}
    //             networkRow.network = network;

    //             (rAssetUptime[asset].withdrawStatus != null) ? networkRow.withdrawStatus = (rAssetUptime[asset].withdrawStatus ? "Online" : "Offline") : networkRow.withdrawStatus = "N/A";
    //             (rAssetUptime[asset].depositStatus != null) ? networkRow.depositStatus = (rAssetUptime[asset].depositStatus  ? "Online" : "Offline") : networkRow.depositStatus = "N/A";
    //             (rAssetUptime[asset].withdrawUptime != null) ? networkRow.withdrawUptime = rAssetUptime[asset].withdrawUptime.toFixed(2) + "%" : networkRow.withdrawUptime = "N/A";
    //             (rAssetUptime[asset].depositUptime != null) ? networkRow.depositUptime = rAssetUptime[asset].depositUptime.toFixed(2) + "%" : networkRow.depositUptime = "N/A";
    //             (rAssetUptime[asset].beginTimestamp != null) ? networkRow.beginTimestamp = rAssetUptime[asset].beginTimestamp : "";
    //             (rAssetUptime[asset].latestTimestamp != null) ? networkRow.latestTimestamp = rAssetUptime[asset].latestTimestamp : "";
    //             // console.log(networkRow)

    //             // Find the index of the existing asset
    //             const assetIndex = formattedAssetUptime.findIndex((item) => item.asset === assetID);

    //             if (assetIndex !== -1) {
    //                 // If the asset exists, add the network to the existing asset
    //                 formattedAssetUptime[assetIndex].networks.push(networkRow);
    //             } else {
    //                 // If the asset does not exist, add a new asset to the formattedAssetUptime array
    //                 const row = {asset: assetID, networks: []}
    //                 row.networks.push(networkRow);
    //                 formattedAssetUptime.push(row);
    //             }

    //         });
    //         console.log(formattedAssetUptime)
    //         setAssetUptime(formattedAssetUptime)
    //         setFilteredItems(formattedAssetUptime)
    //     })
    // }, [])

    const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);
        
    const subHeaderComponentMemo = useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText('');
            }
        };

        return (
            <FilterComponent onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />
        );
    }, [filterText, resetPaginationToggle]);

    const assetUptimeCols = [
        {
            name: 'Asset',
            selector: row => row.asset,
            wrap: true,
            sortable: true,
        },
    ]

    const assetNetworksCols = [
        {
            name: 'Network/Provider',
            selector: row => row.network,
            wrap: true,
        },
        {
            name: 'Current Deposit Status',
            selector: row => row.depositStatus,
            wrap: true,
            conditionalCellStyles: [
                {
                    when: row => row.depositStatus && row.depositStatus == "Offline",
                    style: {
                        backgroundColor: 'oklch(var(--er))',
                        color: 'oklch(var(--erc))'
                    },
                },
                {
                    when: row => row.depositStatus && row.depositStatus == "Online",
                    style: {
                        backgroundColor: 'oklch(var(--su))',
                        color: 'oklch(var(--suc))'
                    },
                },
            ],
        },
        {
            name: 'Current Withdraw Status',
            selector: row => row.withdrawStatus,
            wrap: true,
            conditionalCellStyles: [
                {
                    when: row => row.withdrawStatus && row.withdrawStatus == "Offline",
                    style: {
                        backgroundColor: 'oklch(var(--er))',
                        color: 'oklch(var(--erc))'
                    },
                },
                {
                    when: row => row.withdrawStatus && row.withdrawStatus == "Online",
                    style: {
                        backgroundColor: 'oklch(var(--su))',
                        color: 'oklch(var(--suc))'
                    },
                },
            ],
        },
        {
            name: 'Deposit Uptime',
            selector: row => row.depositUptime,
            wrap: true,
        },
        {
            name: 'Withdraw Uptime',
            selector: row => row.withdrawUptime,
            wrap: true,
        },
        {
            name: 'Begin Gathering Timestamp',
            selector: row => row.beginTimestamp,
            wrap: true,
        },
        {
            name: 'Latest Gathering Timestamp',
            selector: row => row.latestTimestamp,
            wrap: true,
        },
    ]

    const assetNetworksComponent = ({data}) => <DataTable columns={assetNetworksCols} data={data.networks} theme="solarized"/>

    return (
        <div className='container mx-auto overflow-x-auto'>
            <h1 className="text-center text-xl">{ExchangeProfile[params.exchange].name}</h1>

        {/* <div className="collapse collapse-arrow bg-base-200">
                <input type="checkbox"></input>
                <div className="collapse-title text-lg text-center font-medium">
                    Asset Status & Uptime
                </div>
                <div className="collapse-content overflow-auto"> 
                    <DataTable 
                        columns={assetUptimeCols} 
                        data={filteredItems} 
                        defaultSortFieldId={1}
                        theme="solarized"
                        expandableRows={row => row.networks.length > 0}
                        expandableRowDisabled={row => !row.networks}
                        expandableRowsComponent={assetNetworksComponent}
                        pagination
                        paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                        subHeader
                        subHeaderComponent={subHeaderComponentMemo}
                    />
                </div>
            </div> */}
        </div>
    )
}
