import {forwardRef, useState, useMemo} from "react";

export default function List(props){
    const data = props.data || []

    //create a new array by filtering the original array
    // Memoize the filtered data
    const filteredData = useMemo(() => {

        return data.filter((el) => {
            // If no input, return the original
            if (props.input === '') {
                return el; 
            }
            // Return the item which contains the user input
            else {
                return el.toLowerCase().includes(props.input.toLowerCase());
            }
        });
    }, [data,props.input]);

    return (
        <>
            {data.length > 0 ? (
                <ul>
                    {filteredData.map((item) => (
                        <li
                            key={item}
                            className="p-0.5"
                            onClick={() => {
                                console.log("test");
                                props.selectedItem(item);
                            }}
                        >
                            {item}
                        </li>
                    ))}
                </ul>
            ) : (
                <p>No data available</p>
            )}
        </>
    )
}
  