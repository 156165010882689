import { Link } from "react-router-dom"

export default function NavBar(props) {
    //fix for dropdown menu not closing when clicking outside of it
    const handleClick = () => {
        const elem = document.activeElement;
        if (elem) {
          elem?.blur();
        }
      };

    return (
        <div className="drawer drawer-end">
            <input id="my-drawer-3" type="checkbox" className="drawer-toggle" /> 

            <div className="drawer-content flex flex-col ">
                {/* Navbar */}
                <div className="w-full navbar bg-base-100 flex justify-between items-center">
                    <div className="absolute left-1/2 transform -translate-x-1/2">
                        <Link className="flex pb-2" to="/">
                            {/* <img src="https://assets.marketspy.au/imgs/marketspy-logo-black.svg" className="w-12 sm:w-12 md:w-12 lg:w-16 xl:w-16 h-auto p-2 logo-dark-colour-filter" /> */}
                            <p className='text-2xl sm:text-2xl lg:text-3xl font-medium text-center p-2'>Market Spy</p>
                        </Link>
                    </div>
                    <div className="flex-1 px-2 mx-2 flex justify-end">
                        <div className="flex-none lg:hidden">
                            <label htmlFor="my-drawer-3" aria-label="open sidebar" className="btn btn-square btn-ghost">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="inline-block w-6 h-6 stroke-current"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16"></path></svg>
                            </label>
                        </div> 
                        <div className="flex-none hidden lg:block">
                            <ul className="menu menu-horizontal">
                                {/* Navbar menu content here */}
                                <li><Link to="/">Homepage</Link></li>
                                <li><Link to="/exchanges">Exchanges</Link></li>
                            </ul>
                        </div>
                    </div>
                </div>
                {/* Page content here */}

                {...props.children}

            </div> 
            <div className="drawer-side">
                <label htmlFor="my-drawer-3" aria-label="close sidebar" className="drawer-overlay"></label> 
                <ul className="menu p-4 w-80 min-h-full bg-base-100">
                {/* Sidebar content here */}
                <li><Link onClick={handleClick} to="/">Homepage</Link></li>
                <li><Link onClick={handleClick} to="/exchanges">Exchanges</Link></li>
                </ul>
            </div>
        </div>
    )
}

// export default function NavBar() {
//     //fix for dropdown menu not closing when clicking outside of it
//     const handleClick = () => {
//         const elem = document.activeElement;
//         if (elem) {
//           elem?.blur();
//         }
//       };
    
//     return (
//         <div className="navbar bg-base-100 z-100">
//             <div className="navbar-start">
//                 <div className="dropdown">
//                     <div tabIndex={0} role="button" className="btn btn-ghost btn-circle">
//                         <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h7" /></svg>
//                     </div>
//                     <ul tabIndex={0} className="menu menu-sm dropdown-content mt-3 z-[1] p-2 shadow bg-base-100 rounded-box w-52">
//                         <li><Link to="/" onClick={handleClick}>Homepage</Link></li>
//                         <li className="z-10"><Link to="/exchanges" onClick={handleClick}>Exchanges</Link></li>
//                         {/* <li><Link to="/about">About</Link></li> */}
//                     </ul>
//                 </div>
//             </div>
//             <div className="navbar-center">
//                 <Link className="flex justify-center pb-2" to="/">
//                     <img src="https://assets.marketspy.au/imgs/marketspy-logo-black.svg" className="w-12 h-12 p-2 logo-dark-colour-filter" />
//                     <p className='text-3xl font-medium text-center p-2'>Market Spy</p>
//                 </Link>
//             </div>
//             <div className="navbar-end">
//                 {/* <button className="btn btn-ghost btn-circle">
//                     <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" /></svg>
//                 </button>
//                 <button className="btn btn-ghost btn-circle">
//                     <div className="indicator">
//                         <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9" /></svg>
//                         <span className="badge badge-xs badge-primary indicator-item"></span>
//                     </div>
//                 </button> */}
//             </div>
//         </div>
//     )
// }