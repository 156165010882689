import React, {createContext, useReducer, useContext} from "react"

import { CustomFeesReducer, CustomFeesActions } from "../reducers/CustomFeesReducer";
import { ExchangeFees } from "../constants/ExchangeFees";

const localExchangeFees = JSON.parse(localStorage.getItem("customFees"))
let exchangeFees = ExchangeFees

if (localExchangeFees != null) {
    exchangeFees = localExchangeFees
    //Check for missing exchanges, any new ones added
    Object.keys(ExchangeFees).map(exchange => {
        if (!exchangeFees[exchange]) {
            exchangeFees[exchange] = ExchangeFees[exchange]
        }
    })
    console.log(`Local storage: ${JSON.stringify(localExchangeFees)}`)
} else {
    console.log(`na no local storage ${localExchangeFees}`)
}

const initialState = {"fees": {...exchangeFees}, "count": 0}

export const CustomFeesContext = createContext(initialState);
 
export function CustomFeesProvider({children}){
    const [state, dispatch] = useReducer(CustomFeesReducer,initialState)
    const value = {
        customFees: state,
        setCustomFees: (data) => {
            console.log(data)
            dispatch({type: CustomFeesActions.SET_CUSTOM_FEES, data})
        },
    }

    return (
        <CustomFeesContext.Provider value={value}>
            {children}
        </CustomFeesContext.Provider>
    )
}