import { Link } from "react-router-dom"

export default function Exchanges() {
    return (
        <div className="container mx-auto overflow-x-auto">
            <div className="container overflow-x-auto">
                <table className="table table-zebra container overflow-x-auto">
                    {/* head */}
                    <thead>
                        <tr>
                            <th>Exchange</th>
                            <th>Website</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td><Link to="/exchanges/btcmarkets" className="link-secondary">BTC Markets</Link></td>
                            <td>https://www.btcmarkets.net/</td>
                        </tr>
                        <tr>
                            <td>CoinSpot</td>
                            <td>https://www.coinspot.com.au/</td>
                        </tr>
                        <tr>
                            <td><Link to="/exchanges/whitebit" className="link-secondary">WhiteBIT</Link></td>
                            <td>https://whitebit.com/</td>
                        </tr>
                        <tr>
                            <td>CoinJar</td>
                            <td>https://www.coinjar.com/</td>
                        </tr>
                        <tr>
                            <td>Independent Reserve</td>
                            <td>https://www.independentreserve.com/</td>
                        </tr>
                        <tr>
                            <td><Link to="/exchanges/okx" className="link-secondary">OKX</Link></td>
                            <td>https://www.okx.com/</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    )
}