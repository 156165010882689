import React, {Component, useEffect, useState, useContext, useRef, useMemo} from 'react'
import axios from 'axios';

import Pusher from 'pusher-js'
//Pusher.logToConsole = true;

import {ExchangeDataContext} from '../contexts/ExchangeDataContext';
import { CustomFeesContext } from '../contexts/CustomFeesContext';

import { ExchangeNames } from '../constants/ExchangeNames';
import { CurrencyFormat, CurrencyFormat2Digit, CurrencyFormat3Digit, CurrencyFormat4Digit } from '../utils/CurrencyFormat';
import { ExchangeFees } from '../constants/ExchangeFees';
import { ExchangeReferralLinks } from '../constants/ExchangeReferralLinks';
import { BackendLinks } from '../constants/BackendLinks';

import Popup from './Popup';
import CustomFees from './CustomFees';
import Button from './UI/Button';
import List from './UI/List';
//const dataWebsocketURL = "wss://ws.marketspy.au"

export default function PriceComparison() {
    const {orderbook, setOrderbook} = useContext(ExchangeDataContext)
    const {customFees, setCustomFees} = useContext(CustomFeesContext)
    const [pairs, setPairs] = useState({"primary": [], "secondary": []})
    const [firstCurrency, setFirstCurrency] = useState("BTC")
    const [secondCurrency, setSecondCurrency] = useState("AUD")
    const [amount, setAmount] = useState(1)
    const [exchangePrices, setExchangePrices] = useState(false)
    const [highValue, setHighValue] = useState(false)
    const [totalOrderCalculatedPerExchange, setTotalOrderCalculatedPerExchange] = useState({})
    const [sellMode, setSellMode] = useState(true)
    const [asksOrBids, setAsksOrBids] = useState("asks")
    const [mainCurrencySearch, setMainCurrencySearch] = useState("")
    const [secondaryCurrencySearch, setSecondaryCurrencySearch] = useState("")

    const ws = useRef(null)
    const wsChannel = useRef(null)
    const activePair = useRef(`${firstCurrency}-${secondCurrency}`)
    const receivedInitial = useRef(false)
    const sellModeButtonText = useRef("Change to Buy Mode")
    const sellModeButtonColor = useRef("text-green-800")

    function addZeroes(num) {
        if (num > 1) return Number(num).toFixed(2)
        num = num.toString()
        const dec = num.split('.')[1]
        const len = dec && dec.length > 2 ? dec.length : 2
        return Number(num).toFixed(len)
    }

    useMemo(() => {
        axios.get(`${BackendLinks["pairs"]}`).then((response) => {
            if (response.data == null) return

            const pairs = response.data
            const splitPairs = {"primary": [], "secondary": []}
            console.log(pairs)

            pairs.map(pair => {
                const [first, second] = pair.split("-")
                if (!splitPairs.primary.includes(first)) 
                    splitPairs.primary.push(first)
                
                if (!splitPairs.secondary.includes(second)) 
                    splitPairs.secondary.push(second)
            })

            setPairs(splitPairs)
        })
    }, [])

    useMemo(() => {

        // const dataWebsocketURL = `wss://free.nyc1.piesocket.com/v3/${firstCurrency}-${secondCurrency}?api_key=JxLBxmmUQ3XVz148sbOvNWFxkUyiXBGyEPtwpL1J&notify_self=1`
        // if (wsChannel.current){

        //     wsChannel.current.disconnect()
        // }

        const newPair = `${firstCurrency}-${secondCurrency}`

        if (activePair.current != newPair){
            console.log(`${activePair.current}`)
            //ws.current.emit("leave room", `${activePair.current}`)
            ws.current.disconnect()
            activePair.current = newPair
            console.log(`${activePair.current}`)
        }

        //ws.current = new WebSocket(dataWebsocketURL)

        axios.get(`${BackendLinks["api"]}/crypto-orderbooks?pair=${activePair.current}`).then((response) => {
            if (response.data == null){
                return
            }
            receivedInitial.current = true
            //console.log("intitial shix")
            let newData = {}
            response.data.orderbooks.map(orderbook => {
                newData[orderbook.exchangeName] = orderbook.orderbookData
            })
            
            setOrderbook(["initial", activePair.current, newData])
        })

        //ws.current = io("http://192.168.86.172")
        console.log(`${BackendLinks['ws'].host} | ${BackendLinks['ws'].port}`)
        try{
            ws.current = new Pusher('marketspykey', {
                cluster: 'mt1',
                wsHost: BackendLinks['ws'].host,
                wsPort: BackendLinks['ws'].port,
                httpHost: BackendLinks['ws'].host,
                httpPort: BackendLinks['ws'].port,
                httpsPort: BackendLinks['ws'].port,
                forceTLS: false,
                encrypted: true,
                disableStats: true,
                enabledTransports: ['ws', 'wss'],
            })
        } catch (e) {
            console.log(e)
        }
        console.log("we are connected to pusher")
        wsChannel.current = ws.current.subscribe(activePair.current)
        console.log("we are subscribed to pusher")
        console.log(wsChannel.current)
        wsChannel.current.bind('update', (message) => {
            //console.log(message)
            setOrderbook(["update", newPair, message])
        })

    }, [firstCurrency, secondCurrency])

    function calculateBestPrice(){
        //console.log(orderbook)
        //const exchangeData = orderbook["ExchangeData"]
        const exchangeData = orderbook["cryptoToken"]
        let currency = `${firstCurrency}-${secondCurrency}`
        let calcExchangePrices = {}
        let totalOrderCostsPerExchange = {}
        let orderAmount = 0
        let ordersRequired = []

        if (exchangeData[currency]) {
            //console.log("yes")
            //const exchangesInOrderbook = Object.keys(exchangeData)
            const exchangesInOrderbook = Object.keys(exchangeData[currency])
            //console.log(exchangesInOrderbook)
            //exchangesInOrderbook.map(exchangeName => {
            exchangesInOrderbook.map(exchangeName => {

                //if (exchangeData[exchangeName][currency] != null){
                    if (exchangeData[currency][exchangeName] != null){
                    
                    //let pricing = [parseFloat(exchangeData[exchangeName][currency]["asks"][0][0]), parseFloat(exchangeData[exchangeName][currency]["asks"][0][1])]
                    let pricing = [parseFloat(exchangeData[currency][exchangeName][asksOrBids][0][0]), parseFloat(exchangeData[currency][exchangeName][asksOrBids][0][1])]

                    amount * pricing[0] >= 250000 ? setHighValue(true) : setHighValue(false)
                    
                    // Getting exchange fees based on order size (volume-based fees)
                    if (highValue != true) {
                        let feeAmount = 0
                        let totalOrderPrice = (pricing[0] * amount)

                        let checkedFeeExchangeName = exchangeName

                        if (exchangeName == "kraken"){
                            if (currency == "USDT-AUD"){
                                checkedFeeExchangeName = "krakenFX"
                            } else {
                                checkedFeeExchangeName = "krakenSpot"
                            }
                        }   
                        //console.log(customFees["fees"])
                        customFees["fees"][checkedFeeExchangeName].map(feeDetails => {

                            if (feeDetails[0] <= totalOrderPrice && feeDetails[1] >= totalOrderPrice) {
                                feeAmount = feeDetails[2]
                            }
                        })

                        pricing = [...pricing, feeAmount]
                    }

                    calcExchangePrices[exchangeName] = pricing

                    // Calculate amount of orders to execute to fulfill amount
                    let orderFilled = false
                    let filledOrder = parseFloat(amount)
                    let feesToPay = 0
                    let averageWeightedPrice = 0
                    let valuesToCalculateAveragePrice = []
                    //let orderFilledCalculations = {}
                    //orderFilledCalculations[exchangeName] = {orderFilled: false, filledOrder: parseFloat(amount), feesToPay: 0, averageWeightedPrice: 0, valuesToCalculateAveragePrice: [], ordersRequired: []}

                    //exchangeData[exchangeName][currency]["asks"].map(ask => {
                    exchangeData[currency][exchangeName][asksOrBids].map(ask => {
                        if (orderFilled == false) {
                            let currentOrderFee = 0
                            let exchangeFee = pricing[2]
                            let percentageOfOrder = 100

                            if (parseFloat(ask[1]) < filledOrder) {
                                currentOrderFee = (exchangeFee / 100) * (ask[1] * ask[0])
                                filledOrder -= ask[1]

                                valuesToCalculateAveragePrice.push([parseFloat(ask[0]), parseFloat(ask[1])])

                                orderFilled = false
                            } else {
                                //console.log(`${filledOrder} | ${ask[0]} | ${ask[1]}`)
                                currentOrderFee = (exchangeFee / 100) * (filledOrder * ask[0])

                                percentageOfOrder = (filledOrder / ask[1]) * 100
                                //console.log('ye')
                                valuesToCalculateAveragePrice.push([parseFloat(ask[0]), parseFloat(filledOrder)])
                                //console.log(`${exchangeName} | ${currentOrderFee} | ${ask} | ${orderFilledCalculations[exchangeName].filledOrder}`)
                                orderFilled = true
                            }

                            if (!ordersRequired[exchangeName]) {
                                //console.log(`${exchangeName} none`)
                                ordersRequired[exchangeName] = []
                            }

                            ask[2] = currentOrderFee
                            feesToPay += currentOrderFee

                            ordersRequired[exchangeName].push([...ask, percentageOfOrder.toFixed(2)])
                        } else {
                            //console.log(`${exchangeName} - order filled ${JSON.stringify(orderFilledCalculations[exchangeName].ordersRequired[exchangeName])}`)
                        }
                    })

                    // Getting cheapest exchange cost
                    let awpA = 0
                    let awpB = 0

                    valuesToCalculateAveragePrice.map(order => {
                        awpA += (order[1]*order[0])
                        awpB += order[1]
                    })
                    
                    awpA == 0 ? averageWeightedPrice = 0 : averageWeightedPrice = awpA / awpB

                    // if (calculatedCheapestExchange == false) {
                    //     calculatedCheapestExchange = [exchangeName, averageWeightedPrice, pricing[1], pricing[2], feesToPay]
                    // } else {
                    //     if ((averageWeightedPrice + feesToPay) < (calculatedCheapestExchange[1] + calculatedCheapestExchange[4])){
                    //         calculatedCheapestExchange = [exchangeName, averageWeightedPrice, pricing[1], pricing[2], feesToPay]
                    //     }
                    // }
                    const totalOrderCost = (sellMode == true ? ((amount * averageWeightedPrice) - feesToPay) : ((amount * averageWeightedPrice) + feesToPay))
                    totalOrderCostsPerExchange[exchangeName] = [averageWeightedPrice, pricing[1], pricing[2], feesToPay, totalOrderCost]

                    //console.log(JSON.stringify(totalOrderCostsPerExchange))

                    calcExchangePrices[exchangeName] = [...calcExchangePrices[exchangeName], ordersRequired[exchangeName], exchangeData[currency][exchangeName]["timestamp"]]
                }
            })
        } else {
            console.log('na')
        }

        let calcExchangePricesSorted = false
        let totalOrderCostsPerExchangeSorted = false

        if (sellMode === true){
            calcExchangePricesSorted = Object.fromEntries(
                Object.entries(calcExchangePrices).sort(([,a],[,b]) => b[0]-a[0])
            );

            totalOrderCostsPerExchangeSorted = Object.fromEntries(
                Object.entries(totalOrderCostsPerExchange).sort(([,a],[,b]) => b[4]-a[4])
            );
        }else{
            calcExchangePricesSorted = Object.fromEntries(
                Object.entries(calcExchangePrices).sort(([,a],[,b]) => a[0]-b[0])
            );

            totalOrderCostsPerExchangeSorted = Object.fromEntries(
                Object.entries(totalOrderCostsPerExchange).sort(([,a],[,b]) => a[4]-b[4])
            );
        }
        
        setExchangePrices(calcExchangePricesSorted)
        setTotalOrderCalculatedPerExchange(totalOrderCostsPerExchangeSorted)
    }

    useMemo(() => {
        calculateBestPrice()
        //console.log(orderbook["cryptoToken"][`${firstCurrency}-${secondCurrency}`]["kraken"]["timestamp"])
    }, [orderbook["count"], firstCurrency, secondCurrency, amount])

    useMemo(() => {
        const newSellMode = !sellMode

        // setSellMode(newSellMode)
        newSellMode == false ? setAsksOrBids("bids") : setAsksOrBids("asks")
        sellModeButtonText.current = (newSellMode === true ? "Change to Sell mode" : "Change to Buy mode")
        sellModeButtonColor.current = (newSellMode === true ? "text-red-800" : "text-green-800")
    }, [sellMode])

    return (
        <div className=''>
            {exchangePrices != false ?
                <div className=''>
                    <div className='text-center'>
                        {/* <p>{JSON.stringify(orderbook)}</p> */}
                        <div className='pb-2'>
                            <div>
                                <span>Choose your pair below</span>
                            </div>
                            <div className='flex justify-center'>
                                {/* <select className='select select-primary'
                                    onChange={(e) => {
                                    console.log(e.target.value) 
                                    setFirstCurrency(e.target.value)}}>
                                    {
                                        pairs.primary.map(firstAsset => {
                                            return(
                                                <option value={firstAsset}>{firstAsset}</option>
                                            )
                                        })
                                    }
                                </select> */}
                                <div className="dropdown">
                                    <div tabIndex={0} role="button" className="btn m-1 border-solid border border-sky-500">{firstCurrency} &#9660;</div>
                                    <ul tabIndex={0} className="block dropdown-content menu bg-base-100 rounded-box z-[1] w-32 shadow max-h-64 overflow-x-auto border-solid border border-sky-500">
                                        <input type="text" placeholder="Type here..." className="input input-bordered w-full max-w-xs pb-1.5" onChange={(e)=>setMainCurrencySearch(e.target.value)}/>
                                        <div className="">
                                            {pairs.primary.length > 0 ? (
                                                <List
                                                    data={pairs.primary}
                                                    input={mainCurrencySearch}
                                                    selectedItem={(selection) => setFirstCurrency(selection)}
                                                />
                                            ) : (
                                                <p>Loading...</p>
                                            )}
                                        </div>
                                    </ul>
                                </div>
                                <span className='font-bold p-1 pt-3'>/</span>
                                <div className="dropdown">
                                    <div tabIndex={0} role="button" className="btn m-1 border-solid border border-sky-500">{secondCurrency} &#9660;</div>
                                    <ul tabIndex={0} className="block dropdown-content menu bg-base-100 rounded-box z-[1] w-32 shadow max-h-64 overflow-x-auto border-solid border border-sky-500">
                                        <input type="text" placeholder="Type here..." className="input input-bordered w-full max-w-xs pb-1.5" onChange={(e)=>setSecondaryCurrencySearch(e.target.value)}/>
                                        <div className="">
                                            <List data={pairs.secondary} input={secondaryCurrencySearch} selectedItem={(selection) => setSecondCurrency(selection)}/>
                                        </div>
                                    </ul>
                                </div>
                                {/* <select className='select select-primary'
                                    onChange={(e) => {
                                    console.log(e.target.value) 
                                    setSecondCurrency(e.target.value)}}>
                                    {
                                        pairs.secondary.map(secondAsset => {
                                            return(
                                                <option value={secondAsset}>{secondAsset}</option>
                                            )
                                        })
                                    }
                                </select> */}
                            </div>
                            <div className='pt-2'>
                                <span>Enter {firstCurrency} order size below</span>
                            </div>
                            <div>
                                <input type="number" 
                                className='input input-bordered input-primary'
                                value={amount} onChange={(e) => setAmount(e.target.value)}/>
                            </div>
                        </div>
                        <button type="button" className={`btn ${sellModeButtonColor.current}`} onClick={() => setSellMode(!sellMode)}>{sellModeButtonText.current}</button>
                        <CustomFees/>
                        <div className='pt-2'></div>
                    </div>
                    {highValue == true 
                        ? 
                            <p>Your order is worth $250k+ AUD, go OTC to save on fees.</p> 
                        : 
                            <div className="w-screen overflow-scroll">
                                <table className='table table-zebra table-auto'>
                                    <thead>
                                        <tr>
                                            <th>Exchange</th>
                                            <th>Avg. Price</th>
                                            <th>Fee</th>
                                            {sellMode === true ? <th>Total (Avg. Price - Fee)</th> : <th>Total (Avg. Price + Fee)</th>}
                                        </tr> 
                                    </thead>
                                    <tbody className="">
                                        {Object.keys(totalOrderCalculatedPerExchange).map((exchangeName) => {
                                            return(
                                                <tr key={`totalOrder-${exchangeName}`}>
                                                    <td><a href={`${ExchangeReferralLinks[exchangeName]}`} target='_blank' className='text-decoration-line: underline'>{ExchangeNames[exchangeName]}</a></td>
                                                    <td>{CurrencyFormat.format(addZeroes(totalOrderCalculatedPerExchange[exchangeName][0]))}</td>
                                                    <td>{CurrencyFormat2Digit.format(totalOrderCalculatedPerExchange[exchangeName][3])} ({totalOrderCalculatedPerExchange[exchangeName][2]}%)</td>
                                                    <td><b>{CurrencyFormat2Digit.format(totalOrderCalculatedPerExchange[exchangeName][4])}</b></td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                    {/* <tr>
                                        <td width={"40%"}>{ExchangeNames[cheapestExchange[0]]}</td>
                                        <td width={"20%"}>{CurrencyFormat3Digit.format(cheapestExchange[1])}</td>
                                        <td width={"20%"}>{CurrencyFormat2Digit.format(cheapestExchange[4])} ({cheapestExchange[3]}%)</td>
                                        <td width={"20%"}>{CurrencyFormat2Digit.format((amount * cheapestExchange[1]) + cheapestExchange[4])}</td>
                                    </tr> */}
                                </table>
                            </div>
                    }
                    <br></br>
                    <h3 className='text-center font-bold'>Breaking Down Your Order</h3>
                    <p className="text-center">How your potential order may be fulfilled</p>
                    <div className='w-screen overflow-auto'>
                        <table className='table table-zebra'>
                            <thead>
                                <tr>
                                    {Object.keys(exchangePrices).map((exchangeName) => {
                                        return (
                                            <td style={{textAlign: "center"}}><b>{ExchangeNames[exchangeName]}</b></td>
                                        )
                                    })}
                                </tr>
                                <tr>
                                    {Object.keys(exchangePrices).map((exchangeName) => {
                                        return (
                                            <td style={{textAlign: "center", fontSize: "11px"}}>Last Orderbook Change: <b>{new Date(exchangePrices[exchangeName][4]).toUTCString()}</b></td>
                                            //<td style={{textAlign: "center", fontSize: "11px"}}>Last Change: {exchangePrices[exchangeName][4]}</td>
                                        )
                                    })}
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    {Object.keys(exchangePrices).map((exchangeName) => {
                                        return (
                                            <td style={{verticalAlign: "top"}}>
                                                <table className='table table-zebra'>
                                                    <thead>
                                                        <tr>
                                                            <th>Price</th>
                                                            <th>Order Size</th>
                                                            <th>Fee to pay</th>
                                                            <th>% of order</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {typeof exchangePrices[exchangeName][3] == "object" && exchangePrices[exchangeName][3].map(order => {
                                                            return (
                                                                <tr key={`${order[0]}`}>
                                                                    <td>{CurrencyFormat.format(addZeroes(order[0]))}</td>
                                                                    <td>{parseFloat(order[1]).toFixed(5)}</td>
                                                                    <td>{CurrencyFormat2Digit.format(order[2])}</td>
                                                                    <td>{order[3]}%</td>
                                                                </tr>
                                                            )
                                                        })}
                                                    </tbody>
                                                </table>
                                            </td>
                                        )
                                    })}
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            : <p>loading</p>
            }
        </div>
    )
}
