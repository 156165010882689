// Source: https://www.iso.org/obp/ (ISO 3166)

export const Countries = {
    ["au"]: {name: "Australia", flag: "🇦🇺"},
    ["bs"]: {name: "Bahamas", flag: "🇧🇸"},
    ["bh"]: {name: "Bahrain", flag: "🇧🇭"},
    ["bg"]: {name: "Bulgaria", flag: "🇧🇬"},
    ["br"]: {name: "Brazil", flag: "🇧🇷"},
    ["bvi"]: {name: "British Virgin Islands", flag: "🇻🇬"},
    ["cz"]: {name: "Czech Republic", flag: "🇨🇿"},
    ["el"]: {name: "El Salvador", flag: "🇸🇻"},
    ["ee"]: {name: "Estonia", flag: "🇪🇪"},
    ["fr"]: {name: "France", flag: "🇫🇷"},
    ["ge"]: {name: "Georgia", flag: "🇬🇪"},
    ["hk"]: {name: "Hong Kong", flag:"🇭🇰"},
    ["ie"]: {name: "Ireland", flag: "🇮🇪"},
    ["id"]: {name: "Indonesia", flag: "🇮🇩"},
    ["it"]: {name: "Italy", flag: "🇮🇹"},
    ["jp"]: {name: "Japan", flag: "🇯🇵"},
    ["kz"]: {name: "Kazakhstan", flag: "🇰🇿"},
    ["lt"]: {name: "Lithuania", flag: "🇱🇹"},
    ["mt"]: {name: "Malta", flag: "🇲🇹"},
    ["mx"]: {name: "Mexico", flag: "🇲🇽"},
    ["nl"]: {name: "Netherlands", flag: "🇳🇱"},
    ["nz"]: {name: "New Zealand", flag: "🇳🇿"},
    ["pl"]: {name: "Poland", flag: "🇵🇱"},
    ["sc"]: {name: "Seychelles", flag: "🇸🇨"},
    ["sg"]: {name: "Singapore", flag: "🇸🇬"},
    ["za"]: {name: "South Africa", flag: "🇿🇦"},
    ["es"]: {name: "Spain", flag: "🇪🇸"},
    ["se"]: {name: "Sweden", flag: "🇸🇪"},
    ["th"]: {name: "Thailand", flag: "🇹🇭"},
    ["tr"]: {name: "Turkey", flag: "🇹🇷"},
    ["ae"]: {name: "UAE", flag: "🇦🇪"},
    ["uk"]: {name: "UK", flag: "🇬🇧󠁧"},
    ["us"]: {name: "USA", flag: "🇺🇸"},
}