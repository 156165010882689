import React, {Component} from 'react'
import '../style.css'
import { NavLink as Link } from 'react-router-dom';

import NavBar from './UI/NavBar';

export default function Header() {
    return (
        <div>
            <NavBar />
        </div>
    )
}
//filter: invert(85%) sepia(11%) saturate(96%) hue-rotate(178deg) brightness(98%) contrast(85%);