export const ExchangeReferralLinks = {
    ["independentreserve"]: "https://www.independentreserve.com/",
    ["btcmarkets"]: "https://btcmarkets.net/",
    ["kraken"]: "https://kraken.com/",
    ["krakenFX"]: "https://kraken.com/",
    ["krakenSpot"]: "https://kraken.com/",
    ["coinjar"]: "https://coinjar.com/",
    ["whitebit"]: "https://whitebit.com/",
    ["okx"]: "https://okx.com/",
    ["binance"]: "https://binance.com/",
    ["bybit"]: "https://bybit.com/",
    ["kucoin"]: "KuCoin",
    ["coinbase"]: "Coinbase Exchange",
    ["cryptocom"]: "Crypto.com Exchange",
    ["gateio"]: "Gate.IO",
    ["huobi"]: "Huobi",
    ["bitget"]: "Bitget",
    ["mexc"]: "MEXC",
    ["poloniex"]: "Poloniex",
    ["bitmart"]: "BitMart",
    ["lbank"]: "LBank",
    ["digifinex"]: "DigiFinex",
    ["bitforex"]: "Bitforex",
    ["coinw"]: "CoinW",
    ["gemini"]: "Gemini",
    ["bitstamp"]: "Bitstamp",
    ["bitstampSpot"]: "Bitstamp (Spot)",
    ["bitstampFX"]: "Bitstamp (FX)",
    ["blockchaincom"]: "Blockchain.com Exchange",
    ["coinspot"]: "https://coinspot.com.au/"
}