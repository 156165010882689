import {forwardRef} from "react";
import clsx from "clsx";
import { VariantProps, cva } from "class-variance-authority";
import { twMerge } from "tailwind-merge";

import '../../styles/Popup.css'

const variants = cva(
    [
        'inline-flex',
        'items-center',
        'justify-center',
        'relative',
        'cursor-pointer',
        'disabled:cursor-not-allowed',
        'tracking-wide',
        'transition',
        'rounded-full',
        'outline-none',
        'focus:scale-[0.98]',
    ],
    {
        variants: {
            variant: {
                primary: [
                    'font-semibold',
                    'bg-indigo-500',
                    'hover:bg-indigo-600',
                    'text-white',
                    'shadow',
                    'hover:shadow-md',
                    'disabled:bg-indigo-500/50',
                    'disabled:shadow',
                    'ring-offset-2',
                    'focus-visible:ring-2',
                    'ring-indigo-500/70',
                ],
                secondary: [
                    'font-normal',
                    'bg-gray-50',
                    'hover:bg-gray-100',
                    'disabled:bg-gray-50',
                    'text-gray-950',
                    'shadow',
                    'border',
                    'border-neutral-200/50',
                    'ring-offset-2',
                    'focus-visible:ring-2',
                    'ring-gray-200',
                ],
                destructive: [
                    'font-semibold',
                    'bg-red-500',
                    'hover:bg-red-600',
                    'text-white',
                    'rounded-full',
                    'shadow',
                    'hover:shadow-md',
                    'disabled:bg-red-500/50',
                    'disabled:shadow',
                    'ring-offset-2',
                    'focus-visible:ring-2',
                    'ring-red-500',
                ],
                ghost: [
                    'font-light',
                    'text-gray-950',
                    'hover:text-gray-600',
                    'disabled:text-gray-950',
                    'ring-gray-300',
                    'focus-visible:ring-1',
                ],
                link: [
                    'font-light',
                    'text-indigo-500',
                    'hover:text-indigo-600',
                    'disabled:text-indigo-500/50',
                    'disabled:no-underline',
                    'hover:underline',
                    'ring-indigo-300',
                    'focus-visible:ring-1',
                ],
            },
            size: {
                small: ['text-sm', 'py-1', 'px-4'],
                default: ['text-base', 'py-2', 'px-8'],
                large: ['text-lg', 'py-3', 'px-12'],
            },
        },
        defaultVariants: {
            variant: 'primary',
            size: 'default',
        },
    }
)

const Button = forwardRef(
(
    {
        children,
        type = 'button',
        className,
        variant,
        size,
        disabled = false,
        ...props
    }, ref
) => (
    <button
        ref={ref}
        disabled={disabled}
        type={type}
        className={twMerge(clsx(variants({ variant, size, className })))}
        {...props}
    >
        {children}
    </button>
));

Button.displayName = 'Button'

export default Button

