import React from "react";
import '../styles/Popup.css'

import Button from "./UI/Button";

export default function Popup(props){
    
    return (props.trigger) ? ( 
        <div className="fixed top-0 left-0 w-full h-screen flex items-center justify-center">
            <div className="relative p-8 w-full bg-white overflow-scroll max-h-[70%] max-w-[70%]">
                <Button type="button" variant="destructive" className="absolute top-2 right-1" onClick={() => props.setTrigger(false)}>Close</Button>
                { props.children }
            </div>
        </div>
    ) : "";
}