import React, {Component, useEffect, useState, useContext, useRef, useMemo} from 'react'

import { ExchangeNames } from '../constants/ExchangeNames';
import { CurrencyFormat, CurrencyFormat2Digit, CurrencyFormat3Digit, CurrencyFormat4Digit } from '../utils/CurrencyFormat';
import { ExchangeFees } from '../constants/ExchangeFees';

import { CustomFeesContext } from '../contexts/CustomFeesContext';

import Button from './UI/Button';

import Popup from './Popup';

export default function CustomFees() {
    const [buttonPopup, setButtonPopup] = useState(false)
    const {customFees, setCustomFees} = useContext(CustomFeesContext)

    function setNewCustomFee(exchange, feeBottom, feeTop, newFee){
        console.log(`${exchange}, ${feeBottom}, ${feeTop}, ${newFee}`)
        ExchangeFees[exchange].map((fee, index) => {
            if (fee[0] == feeBottom && fee[1] == feeTop) {
                let fees = {...customFees["fees"]}
                fees[exchange][index][2] = parseFloat(newFee)
                setCustomFees(fees)
                console.log(fees)
            }
        })
        console.log(customFees)
    }

    function openPopup(){
        console.log(customFees)
        console.log(Object.keys(customFees["fees"]))
        setButtonPopup(true)
    }
                // ExchangeFees["btcmarkets"].map(fees => {
                //     console.log(fees)
                // })

    return (
        <div>
            {/* <button type="button" className="btn" onClick={openPopup}>Custom Fees</button> */}
            <div className="tooltip tooltip-right" data-tip="Set fees for each exchange">
                <button type="button" className="btn" onClick={() => window.customFeesModal.showModal()}>Add Custom Fees</button>
            </div>
            <dialog id="customFeesModal" className='modal'>
                <div className='modal-box w-11/12 max-w-5xl max-h-screen'>
                    <form method="dialog">
                        <button class="btn btn-sm btn-circle btn-ghost absolute right-2 top-4">✕</button>
                    </form>
                    <h3 className="font-extrabold text-lg text-center p-1">Exchange Fees</h3>
                    <p className="text-xs text-center">Autosaves</p>
                    <div className='flex flex-row overflow-auto gap-x-8 max-h-96'>
                        {customFees["fees"] && Object.keys(customFees["fees"]).map((exchangeName) => {

                            return(
                                <div>
                                    <h3 className='text-center font-bold p-2'>{ExchangeNames[exchangeName]}</h3>
                                    <div>
                                        {customFees["fees"][exchangeName].map((fee) => {
                                            return (
                                                <div className='flex flex-row'>
                                                    <div className='flex-col p-1'>
                                                        <p>Between {CurrencyFormat2Digit.format(fee[0])} and {CurrencyFormat2Digit.format(fee[1])} trade volume</p>
                                                        <input type="number" className='input input-bordered' value={fee[2]} onChange={(e) => setNewCustomFee(exchangeName, fee[0], fee[1], e.target.value)}/>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
                <form method="dialog" className="modal-backdrop">
                    <button>close</button>
                </form>
            </dialog>
            
            {/* <Popup trigger={buttonPopup} setTrigger={setButtonPopup}>
                <h3>Exchange Fees</h3>
                <table>
                    {customFees["fees"] && Object.keys(customFees["fees"]).map((exchangeName) => {

                        return(
                            <td>
                                <thead>
                                    <tr>
                                        <h3 style={{textAlign: 'center'}}>{ExchangeNames[exchangeName]}</h3>
                                    </tr>
                                </thead>
                                <tr>
                                    {customFees["fees"][exchangeName].map((fee) => {
                                        return (
                                            <table>
                                                <tr>
                                                    <p>Between {CurrencyFormat2Digit.format(fee[0])} and {CurrencyFormat2Digit.format(fee[1])} trade volume</p>
                                                    <input type="number" value={fee[2]} onChange={(e) => setNewCustomFee(exchangeName, fee[0], fee[1], e.target.value)}/>
                                                </tr>
                                            </table>
                                        )
                                    })}
                                </tr>
                            </td>
                        )
                    })}
                </table>
            </Popup> */}
        </div>
    )
} 