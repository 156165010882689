export const CustomFeesActions = {
    SET_CUSTOM_FEES: "SET_CUSTOM_FEES",
}

export function CustomFeesReducer(state, action) {
    switch (action.type){
        case CustomFeesActions.SET_CUSTOM_FEES:
            return setCustomFees(state, action.data)
        default:
            return state;
    }
}

// [exchangeName, data]
function setCustomFees(currentState, givenData){
    //console.log(givenData)
    let newCustomFees = {...currentState}
    //const initialState = {"fees": {...ExchangeFees}, "count": 0}
    newCustomFees = {"fees": givenData, "count": 0}

    localStorage.setItem("customFees", JSON.stringify(givenData));
    //console.log({...newCustomFees})
    return {...newCustomFees}
}
