export const ExchangeDataActions = {
    SET_EXCHANGE_DATA: "SET_EXCHANGE_DATA",
}

export function ExchangeDataReducer(state, action) {
    switch (action.type){
        case ExchangeDataActions.SET_EXCHANGE_DATA:
            return setExchangeData(state, action.data)
        default:
            return state;
    }
}

// [exchangeName, data]
function setExchangeData(currentState, givenData){
    //console.log(givenData[1])
    let newExchangeData = {...currentState}

    //newExchangeData["ExchangeData"]["counter"] += 1

    // if (givenData[0] == "binance"){
    //     if (newExchangeData["ExchangeData"][givenData[0]][givenData[1]] == null){
    //         newExchangeData["ExchangeData"][givenData[0]][givenData[1]] = {}
    //     }

    //     newExchangeData["ExchangeData"][givenData[0]][givenData[1]] = givenData[2]

    //     return newExchangeData
    // }

    // newExchangeData["ExchangeData"][givenData[0]] = givenData[1]

    //newExchangeData = givenData
    //console.log(currentState)
    //console.log(givenData[0])
    if (givenData[0] == "initial"){
        //console.log(givenData[2])
        //newExchangeData["cryptoToken"] = givenData[1]
        //newExchangeData["count"] += 1
        const exchangeNames = Object.keys(givenData[2])
        //const exchangeName = givenData[2].exchangeName

        if (!newExchangeData["cryptoToken"][givenData[1]]) {
            newExchangeData["cryptoToken"][givenData[1]] = {}
        }

        exchangeNames.forEach(exchangeName => {
            //console.log(exchangeName)
            newExchangeData["cryptoToken"][givenData[1]][exchangeName] = {}
            newExchangeData["cryptoToken"][givenData[1]][exchangeName] = givenData[2][exchangeName]
        })

    } else {
        const exchangeName = givenData[2].exchangeName
        //console.log(givenData[2])
        if (exchangeName!= null){
            if (!newExchangeData["cryptoToken"][givenData[1]]) {
                newExchangeData["cryptoToken"][givenData[1]] = {}
            }
            newExchangeData["cryptoToken"][givenData[1]][exchangeName] = givenData[2].orderbookData
            newExchangeData["count"] += 1
        }
    }

    //console.log(JSON.stringify(newExchangeData))
    //newExchangeData["ExchangeData"] = givenData
    //newExchangeData = []
    //console.log(newExchangeData)
    //console.log("ygf")
    return {...newExchangeData}
}