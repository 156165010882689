export const ExchangeProfile = {
    "btcmarkets": {
        "name": "BTC Markets",
        "countries": ["au"],
        "website": "https://www.btcmarkets.net/",
        "licenses": [
            {name: "AUSTRAC DCE (held by BTC Markets Pty Ltd)", licNum: "Not Public", source: "https://www.btcmarkets.net/regulation", country: "au"},
            {name: "AFSL (held by BTCM Payments Pty Ltd)", licNum: "525840", source: "https://search-afsl.com/Btcm%20Payments%20Limited/afs-licensee/525840/", issueDate: "06-05-2022", country: "au"}
        ],
        "companies": [
            {name: "BTC Markets Pty Ltd", busNum: "ACN: 164 093 887, ABN: 45 164 093 887", source: "https://www.abr.business.gov.au/ABN/View/45164093887", issueDate: "03-06-2013", country: "au"},
            {name: "BTCM Payments Pty Ltd", busNum: "ACN: 643 241 829, ABN: 85 643 241 829", source: "https://www.abr.business.gov.au/ABN/View?abn=85643241829", issueDate: "05-08-2020", country: "au"}
        ],
        "logo": "https://www.btcmarkets.net/images/logo.svg",
        "logoWidth": "100px",
        "logoHeight": "100px",
        "logoStyle": {
            "padding": "10px"
        }
    },
    "coinspot": {
        "name": "CoinSpot",
        "countries": ["au"],
        "licenses": [
            {name: "AUSTRAC DCE", licNum: "Not Public", source: "https://web.archive.org/web/20230326115147/https://coinspot.zendesk.com/hc/en-us/articles/360000148136-Who-is-AUSTRAC-and-is-CoinSpot-registered-", country: "au"},
        ],
        "companies": [
            {name: "Casey Block Services Pty Ltd", busNum: "ACN: 619 574 186, ABN: 19 619 574 186", source: "https://www.abr.business.gov.au/ABN/View?id=19619574186", issueDate: "06-06-2017", country: "au"}
        ],
        "website": "https://www.coinspot.com.au/",
        "logo": "https://www.coinspot.com.au/images/logo.svg",
        "logoWidth": "100px",
        "logoHeight": "100px",
        "logoStyle": {
            "padding": "10px"
        }
    },
    "whitebit": {
        "name": "WhiteBIT",
        "countries": ["au", "bvi", "cz", "uk", "ge", "hk", "lt", "pl"],
        "licenses": [
            {name: "VASP (held by UAB CLEAR WHITE TECHNOLOGIES Lithuania)", licNum: "D844", source: "https://www.bde.es/f/webbe/SGE/regis/registro_CMV_SCMV_CMC_SCMC.xlsx", country: "es"},
            {name: "Virtual Currency Exchange Operator (under UAB Clear White Technnologies)", licNum: "", source: "https://www.registrucentras.lt/jar/sarasai/vvko.php", country: "lt"},
            {name: "Depository Virtual Currency Operator (under UAB Clear White Technnologies)", licNum: "", source: "https://www.registrucentras.lt/jar/sarasai/dvvpo.php", country: "lt"},            
            {name: "VASP (held by WhiteBIT Tech Sp. Z.o.o)", licNum: "RDWW-552", source: "https://www.slaskie.kas.gov.pl/documents/3559133/11168924/Rejestr+dzia%C5%82alno%C5%9Bci+w+zakresie+walut+wirtualnych.pdf", country: "pl"},
            {name: "VASP (under WhiteBIT Financial Company s.r.o)", licNum: "Registered", source: "https://rejstrik-firem.kurzy.cz/17091357/whitebit-financial-company-sro/", country: "cz"},
            {name: "VASP (under Whitebit Solutions LTD)", licNum: "Registered", source: "https://nra.bg/wps/wcm/connect/nra.bg25863/be180313-b747-44f9-a67e-619e54e038b9/%D0%9F%D1%83%D0%B1%D0%BB%D0%B8%D1%87%D0%B5%D0%BD+%D1%80%D0%B5%D0%B3%D0%B8%D1%81%D1%82%D1%8A%D1%80+15.01.2024+%D0%B3.xlsx?MOD=AJPERES", country: "bg"},
        ],
        "companies": [
            {name: "UAB Clear White Technologies", busNum: "305900184", source: "https://rekvizitai.vz.lt/en/company/clear_white_technologies/", country: "lt"},
            {name: "WhiteBIT Financial Company s.r.o", busNum: "17091357", source: "https://rejstrik-firem.kurzy.cz/17091357/whitebit-financial-company-sro/", country: "cz"},
            {name: "WhiteBIT Tech Sp. Z.o.o", busNum: "7252325774", source: "https://www.biznes.gov.pl/en/wyszukiwarka-firm/wpis/krs/0001003916", country: "pl"},
            {name: "Clear White Solutions Limited", busNum: "Not Public", source: "https://whitebit.com/terms", country: "bvi"},
            {name: "WhiteBIT Operations Australia PTY LTD", busNum: "ABN: 89645644702, ACN: 645644702", source: "https://abr.business.gov.au/ABN/View?id=89645644702", country: "au"},
            {name: "WhiteBIT Solutions LLP", busNum: "OC430512", source: "https://find-and-update.company-information.service.gov.uk/company/OC430512", country: "uk"},
            {name: "WhiteBIT Georgia LLC", busNum: "405520217", source: "https://enreg.reestri.gov.ge/", country: "ge"},
            {name: "Clear White Technologies Limited ", busNum: "CRN: 2977669, BRN: 72229468", source: "https://www.e-services.cr.gov.hk/, https://www.ltddir.com/companies/clear-white-technologies-limited/", issueDate: "15-09-2020", country: "hk"},
            {name: "WhiteBIT Solutions LLP", busNum: "Not Public", source: "https://find-and-update.company-information.service.gov.uk/officers/0iRU-pr-9Zhb95WoAEXcpDj8w_I/appointments", country: "sc"},
            {name: "Whitebit Solutions LTD", busNum: "206962266", source: "https://web.archive.org/web/20240115102130/https://papagal.bg/eik/206962266/7594", country: "bg"},
            {name: "WHİTEBİT TURKEY TEKNOLOJİ ANONİM ŞİRKETİ", busNum: "455287-5", source: "https://www.ticaretsicil.gov.tr/view/hizlierisim/unvansorgulama.php, https://bilgibankasi.ito.org.tr/tr/bilgi-bankasi/firma-bilgileri", country: "tr"},
            {name: "R&D Sprint Effective (Formerly WhiteBIT Financial Solutions)", busNum: "14637422", source: "https://ariregister.rik.ee/eng/company/14637422/RD-sprint-effective-O%C3%9C", active: [false, "Liquidated", "12-12-2023"], country: "ee"},
        ],
        "website": "https://whitebit.com/",
        "logo": "https://whitebit.com/static/images/logo.svg",
        "logoWidth": "100px",
        "logoHeight": "100px",
        "logoStyle": {
            "padding": "10px"
        }
    },
    "coinjar": {
        "name": "CoinJar",
        "countries": ["au", "uk", "us"],
        "licenses": [
            {name: "AUSTRAC DCE (held by CoinJar Australia)", licNum: "DCE100749118-001", source: "https://web.archive.org/web/20230326115147/https://coinspot.zendesk.com/hc/en-us/articles/360000148136-Who-is-AUSTRAC-and-is-CoinSpot-registered-", country: "au"},
            {name: "FCA (held by CoinJar UK)", licNum: "928767", source: "https://register.fca.org.uk/", country: "uk"},
        ],
        "companies": [
            {name: "CoinJar Australia Pty Ltd", busNum: "ACN: 648570807, ABN: 75648570807", source: "https://abr.business.gov.au/ABN/View?abn=75648570807", country: "au"},
            {name: "CoinJar Pty Ltd", busNum: "ACN: 163543824, ABN: 43163543824", source: "https://abr.business.gov.au/ABN/View?abn=43163543824", country: "au"},
            {name: "COINJAR ASSET MANAGEMENT PTY LTD", busNum: "ACN: 625772769, ABN: 26625772769", source: "https://abr.business.gov.au/ABN/View?abn=26625772769", country: "au"},
            {name: "CoinJar UK Limited", busNum: "08905988", source: "https://find-and-update.company-information.service.gov.uk/company/08905988", country: "uk"},
            {name: "CoinJar, Inc.", busNum: "6965446", source: "https://icis.corp.delaware.gov/eCorp/EntitySearch/NameSearch.aspx", country: "us"},
        ],
        "website": "https://www.coinjar.com/",
        "logo": "https://www.coinjar.com/assets/images/coinjar-logo.svg",
        "logoWidth": "100px",
        "logoHeight": "100px",
        "logoStyle": {
            "padding": "10px"
        }
    },
    "independentreserve": {
        "name": "Independent Reserve",
        "countries": ["au", "sg"],
        "licenses": [
            {name: "AUSTRAC DCE (held by Independent Reserve Pty Ltd)", licNum: "DCE-100461150-001", source: "https://www.btcmarkets.net/regulation", country: "au"},
            {name: "Major Payment Institution License (held by Independent Reserve SG Pte Ltd)", licNum: "PS20200517", source: "https://eservices.mas.gov.sg/fid/institution/detail/243463-INDEPENDENT-RESERVE-SG-PTE-LTD", country: "sg"}
        ],
        "companies": [
            {name: "Independent Reserve Pty Ltd", busNum: "ACN: 164257069, ABN: 46164257069", source: "https://www.btcmarkets.net/about", country: "au"},
            {name: "Independent Reserve SG Pte Ltd", busNum: "UEN: 201942383Z", source: "https://www.bizfile.gov.sg/ngbbizfileinternet/faces/oracle/webcenter/portalapp/pages/BizfileHomepage.jspx , https://companieshouse.sg/independent-reserve-sg-201942383Z", country: "sg"}
        ],
        "website": "https://www.independentreserve.com/",
        "logo": "https://www.independentreserve.com/themes/responsive/images/logo.svg",
        "logoWidth": "100px",
        "logoHeight": "100px",
        "logoStyle": {
            "padding": "10px"
        }
    },
    "okx" : {
        name: "OKX",
        countries: ["bh", "hk", "sc", "sg", "us"],
        licenses: [
            {keyField: 1, name: "MVP Preparatory License (held by OKX Middle East Fintech FZE)", licNum: "Registered", source: "https://www.vara.ae/en/licenses-and-register/public-register/okx-middle-east-fintech-fze/", country: "ae"},
            {keyField: 2, name: "Virtual Asset Trading Platform Operator (held by OKX Hong Kong FinTech Company Limited)", licNum: "", source: "https://www.sfc.hk/en/Welcome-to-the-Fintech-Contact-Point/Virtual-assets/Virtual-asset-trading-platforms-operators/Lists-of-virtual-asset-trading-platforms", active: [false, "Pending"], country: "hk"},
            {keyField: 3, name: "Digital Asset Business (held by OKX Bahamas FinTech Company Limited)", licNum: "Registered", source: "https://www.scb.gov.bs/wp-content/uploads/2023/02/DARE-Entities-as-at-31-Jan-23.pdf", issueDate: "2022", country: "bs"},
            {keyField: 4, name: "Virtual Currency Exchange Operator", licNum: "", source: "https://www.scb.gov.bs/wp-content/uploads/2023/02/DARE-Entities-as-at-31-Jan-23.pdf", active: [false, "Company liquidated", "06-12-2023"], country: "lt"},
            {keyField: 5, name: "Depository Virtual Currency Operator", licNum: "", source: "https://www.scb.gov.bs/wp-content/uploads/2023/02/DARE-Entities-as-at-31-Jan-23.pdf", active: [false, "Company liquidated", "06-12-2023"], country: "lt"},
            {keyField: 6, name: "Virtual Currency Exchange Operator (held by OKCoin Japan K.K.)", licNum: "00020", source: "https://www.fsa.go.jp/menkyo/menkyoj/kasoutuka.pdf", issueDate: "30-03-2019", country: "jp"},
            {keyField: 7, name: "Digital Asset Service Provider (DASP) (held by OKX FRANCE TECHNOLOGY COMPANY LIMITED SAS)", licNum: "E2023-106", source: "https://www.amf-france.org/en/professionals/fintech/my-relations-amf/obtain-dasp-authorisation#List_of_DASPs_registered_with_the_AMF", issueDate: "12-12-2023", country: "fr"},
            {keyField: 8, name: "VFA Service Provider (held by OKCOIN Europe LTD)", licNum: "OEUR-VSP-16596", source: "https://www.mfsa.mt/financial-services-register/", issueDate: "02-11-2021", country: "mt"},
            {keyField: 9, name: "Crypto Services Provider (held by OKCoin Europe LTD)", licNum: "LEI: 54930069NLWEIGLHXU42, DNB: R179507", source: "https://www.dnb.nl/en/public-register/information-detail/?registerCode=WWFTAC&relationNumber=R179507", issueDate: "13-07-2021", country: "nl"},
            {keyField: 10, name: "Money Transmitter (held by OKCoin USA Inc.)", licNum: "1767779", states: [
                {keyField: 1, state: "Alabama", license: "Money Transmitter License", licNum: "619", issueDate: "05-12-2018"},
                {keyField: 2, state: "Alaska", license: "Money Transmitter License", licNum: "AKMT-012537", issueDate: "31-03-2020"},
                {keyField: 3, state: "Arizona", license: "Money Transmitter License", licNum: "1005050", issueDate: "11-12-2019"},
                {keyField: 4, state: "Arkansas", license: "Money Transmitter License", licNum: "116440", issueDate: "04-06-2019"},
                {keyField: 5, state: "Connectiut", license: "Money Transmission License", licNum: "MT-1767779", issueDate: "30-09-2019"},
                {keyField: 6, state: "Delaware", license: "Check Seller, Money Transmitter License", licNum: "030441", issueDate: "20-10-2020"},
                {keyField: 7, state: "District of Columbia", license: "Money Transmitter License", licNum: "MTR1767779", issueDate: "05-05-2020"},
                {keyField: 8, state: "Georgia", license: "Money Transmitter License", licNum: "64756", issueDate: "08-03-2019"},
                {keyField: 9, state: "Idaho", license: "Money Transmitters", licNum: "MTL-251", issueDate: "20-12-2019"},
                {keyField: 10, state: "Illinois", license: "Money Transmitter License", licNum: "MT.0000366", issueDate: "07-01-2020"},
                {keyField: 11, state: "Indiana", license: "Money Transmitter License", licNum: "57395", issueDate: "20-01-2022"},
                {keyField: 12, state: "Iowa", license: "Money Services License", licNum: "2019-0023", issueDate: "21-06-2019"},
                {keyField: 13, state: "Kansas", license: "Money Transmitter License", licNum: "MT.0000168", issueDate: "11-02-2020"},
                {keyField: 14, state: "Louisiana", license: "Sale of Checks and Money Transmitters", licNum: "", issueDate: "16-05-2022"},
                {keyField: 15, state: "Louisiana", license: "Virtual Currency Business Activity License", licNum: "", issueDate: "12-06-2023"},
                {keyField: 16, state: "Maine", license: "Money Transmitter License", licNum: "", issueDate: "23-12-2019"},
                {keyField: 17, state: "Maryland", license: "Money Transmitter License", licNum: "1767779", issueDate: "06-03-2023"},
                {keyField: 18, state: "Michigan", license: "Money Transmitter License", licNum: "MT0022704", issueDate: "17-01-2020"},
                {keyField: 19, state: "Minnesota", license: "Money Transmitter License", licNum: "MN-MT-1767779", issueDate: "16-06-2020"},
                {keyField: 20, state: "Nebraska", license: "Money Transmitter License", licNum: "", issueDate: "04-03-2020"},
                {keyField: 21, state: "New Hampshire", license: "Money Transmitter License", licNum: "23134-MT", issueDate: "25-11-2019"},
                {keyField: 22, state: "New Mexico", license: "Money Transmission License", licNum: "", issueDate: "21-10-2019"},
                {keyField: 23, state: "North Carolina", license: "Money Transmitter License", licNum: "183802", issueDate: "26-02-2020"},
                {keyField: 24, state: "North Dakota", license: "Money Transmitter License", licNum: "MT103502", issueDate: "18-09-2019"},
                {keyField: 25, state: "Ohio", license: "Money Transmitter License", licNum: "OHMT194", issueDate: "23-10-2020"},
                {keyField: 26, state: "Oklahoma", license: "Money Transmission License", licNum: "", issueDate: "16-04-2020"},
                {keyField: 27, state: "Oregon", license: "Money Transmitter License", licNum: "30243", issueDate: "04-10-2019"},
                {keyField: 28, state: "Puerto Rico", license: "Money Transmitter License", licNum: "TM-116", issueDate: "13-07-2021"},
                {keyField: 29, state: "Rhode Island", license: "Currency Transmitter", licNum: "20203954CT", issueDate: "01-01-2020"},
                {keyField: 30, state: "Rhode Island", license: "Electronic Money Transfers", licNum: "20193833MT", issueDate: "30-09-2019", active: [false, "Voluntary Surrender", "02-01-2020"]},
                {keyField: 31, state: "South Carolina", license: "Money Transmitter License", licNum: "", issueDate: "05-06-2020"},
                {keyField: 32, state: "South Dakota", license: "Money Transmitter License", licNum: "1767779.MT", issueDate: "16-09-2019"},
                {keyField: 33, state: "Tennesssee", license: "Money Transmitter License", licNum: "1767779", issueDate: "27-04-2020"},
                {keyField: 34, state: "Vermont", license: "Money Transmitter", licNum: "100-156", issueDate: "03-04-2020"},
                {keyField: 35, state: "Vermont", license: "Money Transmitter License - Other Trade Name #1", licNum: "MT-1767779-1", issueDate: "18-07-2023"},
                {keyField: 36, state: "Virginia", license: "Money Order Seller and Money Transmitter License", licNum: "MO-374", issueDate: "03-08-2020"},
                {keyField: 37, state: "Washington", license: "Money Transmitter License (includes Currency Exchange)", licNum: "550-MT-119878", issueDate: "25-09-2019"},
            ], source: "https://www.nmlsconsumeraccess.org/EntityDetails.aspx/COMPANY/1767779", issueDate: "12-09-2017", country: "us"},
        ],
        companies: [
            {name: "OKX AUSTRALIA PTY LTD", busNum: "ACN 636269040, ABN: 22636269040", source: "https://abr.business.gov.au/ABN/View?abn=22636269040", issueDate: "04-11-2019", country: "au"},
            {name: "OKX Bahamas FinTech Company Limited", busNum: "Not Public", source: "https://www.okx.com/help/terms-of-service", issueDate: "Not Public", country: "bs"},
            {name: "OKX Middle East Fintech FZE", busNum: "Local license number: L-2380, Economic record number: 12058800", source: "https://ner.economy.ae/Search_By_BN.aspx", issueDate: "15-09-2022", country: "ae"},
            {name: "Aux Cayes FinTech Co. Ltd.", busNum: "Not Public", source: "https://www.swfinstitute.org/profile/5fe9911c8347c54c1d12cac9", issueDate: "2017", country: "sc"},
            {name: "UAB OKX FinTech", busNum: "306008284", source: "https://rekvizitai.vz.lt/en/company/okx_fintech/legal-entity/", issueDate: "09-02-2022", active: ["false", "Liquidated", "06-12-2023"], country: "lt"},
            {name: "OKX Hong Kong FinTech Company Limited", busNum: "CRN: 3231347, BRN: 74836996", source: "https://www.e-services.cr.gov.hk/, https://www.ltddir.com/companies/okx-hong-kong-fintech-company-limited/", issueDate: "10-02-2023", country: "hk"},
            {name: "OKX Hong Kong Custody Company Limited", buxNum: "CRN: 3235803, BRN: 74881886", source: "https://www.e-services.cr.gov.hk/, https://www.ltddir.com/companies/okx-hong-kong-custody-company-limited/", issueDate: "22-02-2023", country: "hk"},
            {name: "OKX France Technology Company Limited SAS", busNum: "SIREN: 951694587", source: "https://annuaire-entreprises.data.gouv.fr/entreprise/okx-france-technology-company-limited-951694587", issueDate: "14-04-2023", country: "fr"},
            {name: "OKCoin Europe Ltd.", busNum: "C88193", source: "https://www.mfsa.mt/financial-services-register/", issueDate: "02-11-2021", country: "mt"},
            {name: "OKX UK FinTech Company Limited", busNum: "14670378", source: "https://find-and-update.company-information.service.gov.uk/company/14670378", issueDate: "17-02-2023", country: "uk"},
            {name: "OKCOIN EU Ltd.", busNum: "10830622", source: "https://find-and-update.company-information.service.gov.uk/company/10830622", issueDate: "22-06-2017", active: [false, "Dissolved", "12-01-2021"], country: "uk"},

        ],
        website: "https://www.okx.com/",
        logo: "https://www.okx.com/cdn/assets/imgs/2210/DBDC29AD98D75479.png",
        logoWidth: "100px",
        logoHeight: "100px",
        logoStyle: {
            padding: "10px"
        }
    },
}