export const ExchangeNames = {
    ["independentreserve"]: "Independent Reserve",
    ["btcmarkets"]: "BTC Markets",
    ["kraken"]: "Kraken",
    ["krakenFX"]: "Kraken (FX)",
    ["krakenSpot"]: "Kraken (Spot)",
    ["coinjar"]: "CoinJar Exchange",
    ["whitebit"]: "WhiteBIT",
    ["okx"]: "OKX",
    ["binance"]: "Binance",
    ["bybit"]: "Bybit",
    ["kucoin"]: "KuCoin",
    ["coinbase"]: "Coinbase Exchange",
    ["cryptocom"]: "Crypto.com Exchange",
    ["gateio"]: "Gate.IO",
    ["huobi"]: "Huobi",
    ["bitget"]: "Bitget",
    ["mexc"]: "MEXC",
    ["poloniex"]: "Poloniex",
    ["bitmart"]: "BitMart",
    ["lbank"]: "LBank",
    ["digifinex"]: "DigiFinex",
    ["bitforex"]: "Bitforex",
    ["coinw"]: "CoinW",
    ["gemini"]: "Gemini",
    ["bitstamp"]: "Bitstamp",
    ["bitstampSpot"]: "Bitstamp (Spot)",
    ["bitstampFX"]: "Bitstamp (FX)",
    ["blockchaincom"]: "Blockchain.com Exchange",
    ["coinspot"]: "CoinSpot Exchange"
}